
import * as echarts from 'echarts';
import { RGECOLORS, FROMBACKENDTRAJ, DEFINETRAJCOLORS } from '../../utils/const'

export function renderAverageItem(params, api) {
  var bandWidth = api.size([0, 0])[0] 
  var start = api.coord([api.value(0), api.value(1)]);
  var width = Math.log10(api.value(2)+1)*30
  width = bandWidth < width ? bandWidth: width
  var height = 3//bandHeight < api.value(3) ? bandHeight: api.value(3)
  var rectShape = echarts.graphic.clipRectByRect(
    {
      x: start[0]- width/2,
      y: start[1] - height/2,
      width: width,
      height: height
    },
    {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height
    }
  );
  return (
    rectShape && {
      type: 'rect',
      transition: ['shape'],
      shape: rectShape,
      style: api.style()
    }
  );
}
export function renderUpperItem(params, api) {
  var start = api.coord([api.value(0), api.value(1)]);
  var bandWidth = api.size([0, 0])[0]-4 
  var bandHeight = api.size([0, 0])[1]/2-2
  var width = Math.log10(api.value(3)+1)*30
  width = bandWidth < width ? bandWidth: width
  var height = getHeightVal(bandHeight, api.value(4), api.value(7))
  height = width == 0 ? 0 : height
  var rectShape = echarts.graphic.clipRectByRect(
    {
      x: start[0]-width/2,
      y: start[1]-height,
      width: width,
      height: height
    },
    {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height
    }
  );
  return (
    rectShape && {
      type: 'rect',
      transition: ['shape'],
      shape: rectShape,
      style: api.style()
    }
  );
}

export function renderUpperItem1(params, api) {
  var start = api.coord([api.value(0), api.value(1)]);
  var bandWidth = api.size([0, 0])[0] 
  var bandHeight = api.size([0, 0])[1]/2-3
  var width = getSquareWidth(bandWidth, api.value(3))
  var height = width > bandHeight ? bandHeight: width
  var opacity = getSquareOpacity(api.value(4), api.value(7))
  var rectShape = echarts.graphic.clipRectByRect(
    {
      x: start[0]-width/2,
      y: start[1]-height-1,
      width: width,
      height: height
    },
    {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height
    }
  );
  return (
    rectShape && {
      type: 'rect',
      transition: ['shape'],
      shape: rectShape,
      style: {
        ...api.style(),
        opacity: opacity
      }
    }
  );
}

var MyShape = echarts.graphic.extendShape({
  buildPath: function (ctx, shape) {
      ctx.moveTo(shape.x, shape.y);
      ctx.lineTo(shape.x+shape.width, shape.y);
      ctx.lineTo(shape.x+shape.width, shape.y + shape.height);
      ctx.lineTo(shape.x, shape.y + shape.height);
      ctx.closePath();
  }
});
echarts.graphic.registerShape('myCustomShape', MyShape);

export function renderMarkerItem(params, api) {
  var color =api.value(2).split(',')
  var start = api.coord([api.value(0), api.value(1)]);
  var width = api.size([0, 0])[0]-4
  var height = api.size([0, 0])[1]-4
  var linedash = null
  if(api.value(3)=='marker') linedash = 2
  var color0 = DEFINETRAJCOLORS[FROMBACKENDTRAJ[color[0]]]
  var color1 = color.length==1 ? null:DEFINETRAJCOLORS[FROMBACKENDTRAJ[color[1]]] 

  var stroke = color.length==1 ? RGECOLORS[color0] : new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: RGECOLORS[color0]
    },
    {
      offset: 1,
      color: RGECOLORS[color1]
    }])
  return {
      type: 'myCustomShape',
      shape: {
          x: start[0]-width/2,
          y: start[1]-height/2,
          width: width,
          height: height
      },
      style: {
          // fill: 'transparent',
          fill: stroke,
          opacity: 0.25,
          stroke: stroke,
          lineDash: linedash,
          lineWidth: 6
      }
  };
}

const getHeightVal = (bandHeight, val, concept) => {
  var nomal_val = conceptDict[concept]
  var res = Math.abs(Math.log2(val/nomal_val))*20
  res = bandHeight < res ? bandHeight: res
  return res
}

const getSquareWidth = (bandWidth, visits) => {
  var width = Math.log10(visits+1)*30
  width = bandWidth < width ? bandWidth: width
  return width
}

const getSquareOpacity = (val, concept) => {
  var nomal_val = conceptDict[concept]
  var res = Math.abs(Math.log2(val/nomal_val))
  return res
}

export function renderLowerItem(params, api) {
  var start = api.coord([api.value(0), api.value(1)]);
  var bandWidth = api.size([0, 0])[0]-4
  var bandHeight = api.size([0, 0])[1]/2-2
  var width = Math.log10(api.value(5)+1)*30
  width = bandWidth < width ? bandWidth: width
  // var height = bandHeight < api.value(6) ? bandHeight: api.value(6) 
  var height = getHeightVal(bandHeight, api.value(6), api.value(7))
  height = width == 0 ? 0 : height

  var rectShape = echarts.graphic.clipRectByRect(
    {
      x: start[0]-width/2,
      y: start[1], //- height,
      width: width,
      height: height
    },
    {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height
    }
  );
  return (
    rectShape && {
      type: 'rect',
      transition: ['shape'],
      shape: rectShape,
      style: api.style()
    }
  );
}

export function renderLowerItem1(params, api) {
  var start = api.coord([api.value(0), api.value(1)]);
  var bandWidth = api.size([0, 0])[0] 
  var bandHeight = api.size([0, 0])[1]/2-3
  var width = getSquareWidth(bandWidth, api.value(5))
  width = bandWidth < width ? bandWidth: width
  var height = height < bandHeight? height: bandHeight
  height = width == 0 ? 0 : height
  var opacity = getSquareOpacity(api.value(6), api.value(7))
  var rectShape = echarts.graphic.clipRectByRect(
    {
      x: start[0]-width/2,
      y: start[1], //- height,
      width: width,
      height: height
    },
    {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height
    }
  );
  return (
    rectShape && {
      type: 'rect',
      transition: ['shape'],
      shape: rectShape,
      style: {
        ...api.style(),
        opacity: opacity
    }
  });
}

export const labTestChartOption  = {
title: {
  text: 'Clinical Indicator Pattern',
  left: 'center'
},
grid: {
  top: 80, // the size of title + legend + margin
},
legend: {
  // data: ['Normal', 'Above', 'Under', 'Marker'],
  top: 30, // the size of title + margin
  left: 'left', //or 0 or '0%'
  data: [
    {
      name: 'Normal',
      icon:'diamond'

    },
    {
      name: 'Above',
      icon:'diamond'

    },
    {
      name: 'Under',
      icon:'diamond'
    },
    {
      name: 'Fast Progression CKD',
    },
    {
      name: 'CKD',
    },
    {
      name: 'Healthy',
    }
  ],
  left: 'right'
},
dataZoom: [
  {
      id: 'dataZoomY',
      type: 'slider',
      yAxisIndex: [0],
      filterMode: 'empty',
      start: 0,
      end: 100
  },
],
grid: {
  left: 16,
  bottom: '5%',
  right: 5,
  containLabel: true
},
xAxis: {
  type: 'category',
  boundaryGap: false,
  splitLine: {
    show: true
  },
  name: 'age',
  axisLine: {
    show: false
  }
},
yAxis: {
  type: 'category',
  axisLine: {
    show: true
  }
},
series: [
]
};

const conceptDict = {
  'EGFR':100,
  'TBIL': 0.65,
  'BP_DIASTOLIC': 70,
  'BP_SYSTOLIC': 105,
  'WT': 155,
  'HT': 67,
  'CHOLESTEROL': 125,
  'CREATINE_KINASE': 110,
  'HEMOGLOBIN': 28.8,
  'INR': 0.95,
  'ALT_SGPT': 31.5,
  'AST_SGOT': 26.5,
  'ALK': 95.5,
  'HDL': 70,
  'LDL': 70,
  'TRIGLYCERIDES': 85,
  'HBA1C': 5.25,
  'TROPONIN': 0.02
}

const conceptList = ['EGFR',
                      'TBIL',
                      'BP_DIASTOLIC',
                      'BP_SYSTOLIC',
                      'WT',
                      'HT',
                      'CHOLESTEROL',
                      'CREATINE_KINASE',
                      'HEMOGLOBIN',
                      'INR',
                      'ALT_SGPT',
                      'AST_SGOT',
                      'ALK',
                      'HDL',
                      'LDL',
                      'TRIGLYCERIDES',
                      'HBA1C',
                      'TROPONIN']
