import React, {
  useState,
  useEffect,
} from "react";

import ReactECharts from 'echarts-for-react'

import "./LabTestView.less";

import { getLabTestData } from '../../services/api'
import { RGECOLORS, LIGHTCOLOR, DEFINETRAJCOLORS, FROMBACKENDTRAJ } from '../../utils/const'
import { labTestChartOption, renderYaxisLine, 
  renderAverageItem, renderUpperItem, renderLowerItem, renderMarkerItem } from './const'
import { mapConceptList } from "../../utils/conceptNameMap"

const LabTestView = (props) => {
  const { patId } = props;
  const [ chartOption, setChartOption ] = useState(null)
  const [ labTestData, setLabTestData ] = useState()

  useEffect(() => {
    setChartOption(null)
    getLabTestData(patId).then(res => {
      setLabTestData(res.data)
      setChartOption(getOption(res.data))
    })
    // getOption()
  }, [patId])

  const getOption = (info) => {
    return {
      ...labTestChartOption,
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true
          }
        }
      },
      xAxis: {
        type: 'category',
        data: info.ages,
        boundaryGap: true,
        name: 'Age',
        nameLocation: "middle",
        nameTextStyle: {
          align: "left",
          verticalAlign: 'top',
          fontWeight: 'bold',
          padding: 7
        },
        splitArea: {
          show: true
        },
        axisLabel: {
          // fontWeight: 'bold',
          color: 'black'
        }
      },
      tooltip: {
        position: 'left',
        formatter: function (params) {
          if(params.seriesName == 'Normal')
          return (
            info.concepts[params.value[1]] + ', '+
            'age: ' + info.ages[params.value[0]]+', '+ 
            'num: ' + params.value[2]
          ) 
          else if(params.seriesName == 'Above')
            return (
              info.concepts[params.value[1]] + ', '+
              'age: ' + info.ages[params.value[0]]+', '+ 
              'num: ' + params.value[3] + ', '+
              'max_val: '+ params.value[4]
            )
          else if(params.seriesName=='Under') return (
              info.concepts[params.value[1]] + ', '+
              'age: ' + info.ages[params.value[0]]+', '+ 
              'num: ' + params.value[5] + ', '+
              'min_val: '+ params.value[6]
          )
        }
      },
      yAxis: [
        {
          type: 'category',
          data: mapConceptList(info.concepts),
          boundaryGap: true,
          splitArea: {
            show: true
          },
          axisLabel: {
            // fontWeight: 'bold',
            color: 'black',
            fontSize: '12'
          } 
        },
      ],
      series: [
        {
          name: 'Normal',
          type: 'custom',
          renderItem: renderAverageItem,
          encode: {
            x: 0,
            y: 1
          },
          itemStyle: {
            color: RGECOLORS.grey
          },
          data: info.data,
          zIndex: 2,
        },
        {
          name: 'Above',
          type: 'custom',
          renderItem: renderUpperItem,
          itemStyle: {
            color: RGECOLORS.purple,
            opacity: 0.7
          },
          encode: {
            x: 0,
            y: 1
          },
          zIndex: 2, 
          data: info.data,
        },
        {
          name: 'Under',
          type: 'custom',
          renderItem: renderLowerItem,
          itemStyle: {
            color: RGECOLORS.red,
            opacity: 0.7
          },
          encode: {
            x: 0,
            y: 1
          },
          zIndex: 2,
          data: info.data
        },
        {
          name:'Marker',
          type: 'custom',
          tooltip: {
            show: false
          },
          zIndex: 1,
          renderItem: renderMarkerItem,
          data: info.marker
        },
        {
          name: 'Fast Progression CKD',
          color: LIGHTCOLOR[DEFINETRAJCOLORS['traj3']],
          type: 'bar',
          data: []
        },
        {
          name: 'CKD',
          color: LIGHTCOLOR[DEFINETRAJCOLORS['traj2']],
          type: 'bar',
          data: []
        },
        {
          name: 'Healthy',
          color: LIGHTCOLOR[DEFINETRAJCOLORS['traj1']],
          type: 'bar',
          data: []
        }
      ]
    }
  }
  
  return (
    <div style={{"height": '96%'}}>
        {chartOption && 
              <ReactECharts
                style={{"width": '95%', 'height': '95%'}}
                notMerge={true}
                option={chartOption}
                lazyUpdate={false}
        /> }
    </div>
  );
};

export default LabTestView;
