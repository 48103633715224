export const umapOption = {
    title: {
      text: 'UMAP of GraphSage Latent Space',
      left: 'center',
      top: 0
    },
    visualMap: {
      min: 0,
      max: 160,
      dimension: 3,
      orient: 'vertical',
      right: 10,
      top: 'center',
      text: ['mL/min/1.73m^2', ''],
      calculable: true,
      inRange: {
        color: ['#E5E451', '#3E0751']
      }
    },
    tooltip: {
      position: 'right',
      formatter: function (params) {
        return (
          'age: ' + parseFloat(params.value[2]).toFixed(2)  + ', '+
          'egfr: '+ parseFloat(params.value[3]).toFixed(2)
        )
      }
    },
    xAxis: [
      {
        show: false,
        type: 'value'
      }
    ],
    yAxis: [
      {
        show: false,
        type: 'value'
      }
    ],
    series: [
    ]
  };

export const selectOption = [
  {
    label:"eGFR",
    value: 3
  },
  {
    label:"age",
    value: 2
  },
]