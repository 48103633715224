import React, { useState, useEffect, useRef } from "react";
import ReactECharts from 'echarts-for-react';
import 'antd/dist/antd.min.css'
import 'antd/dist/antd.variable.min.css';
import "./PatientView.less";
import { Space, Select, Descriptions} from "antd";
import { getPatRecords } from "../../services/api";
import { genConceptOption } from "../../utils/generateOptions"
import { genderNameMap, raceNameMap, conceptChartOption, yAxisNameMapFun } from './const'
import { LIGHTCOLOR, RGECOLORS } from "../../utils/const";
import { mapConceptName } from "../../utils/conceptNameMap"

const PatientView = (props) => {
  const { patId } = props;
  const [ patDemo, setPatDemo ] = useState()
  const [ patRisk, setPatRisk ] = useState()
  const [ patConcept, setPatConcept ] = useState()
  const [ conceptOption, setConceptOption ] = useState()
  const [ chartOption, setChartOption ] = useState()
  const [ leftConcept, setLeftConcept ] = useState(0)
  const [ rightConcept, setRightConcept ] = useState(1)
  const [ dateOfBirth , setDateOfBirth ] = useState()
  const [ lastVisit, setLastVisit ] = useState()
  // const [ selectColor, setSelectColor ] = useState({width: '130px'})
  useEffect(() => {
    getPatRecords(patId).then(res => {
      setPatRisk(JSON.parse(res.data.risk)[0])
      setPatDemo(renameDemo(JSON.parse(res.data.demo)[0]))
      setPatConcept(res.data.concept)
      setConceptOption(genConceptOption(mapConceptName(res.data.concept)))
      var indexOfeGFR = mapConceptName(res.data.concept).indexOf('eGFR')
      setLeftConcept(indexOfeGFR)
      setChartOption(getOption(res.data.concept, indexOfeGFR, rightConcept))
      setDateOfBirth(res.data.date[0])
      setLastVisit(res.data.date[1])
    })
  }, [patId])

  const renameDemo = (data) => {
    var gender = genderNameMap[data['sex_cd']]
    var genderColor = gender == 'Female'?LIGHTCOLOR.pink : LIGHTCOLOR.cyan
    var race = raceNameMap[data['race_cd']]
    var raceColor = race =='Black'? LIGHTCOLOR.cyan: 'white'
    return {
      'gender':gender,
      'genderColor': genderColor,
      'race':race,
      'raceColor':raceColor
    }
  }

  const onLeftConceptChange = (val) => {
    setLeftConcept(val)
    setChartOption(getOption(patConcept, val, rightConcept))
  }

  const onRightConceptChange = (val) => {
    setRightConcept(val)
    setChartOption(getOption(patConcept, leftConcept, val))
  }
  // const onSelectColorChange = ()=>{
  //   setSelectColor({width: '130px', borderColor:'green', color:'green'})
  // }

  const getOption = (input, var1, var2) => {
    var x_min = Math.min(input[var1][1][0][0], input[var2][1][0][0])
    x_min = Math.floor(x_min)
    return {
      ...conceptChartOption,
      xAxis: {
        name: "Age",
        boundaryGap: false,
        nameLocation: "middle",
        nameTextStyle: {
          align: "left",
          verticalAlign: 'top',
          padding: [5, 0 ,0, 0],
          fontWeight: 'bold'
        },
        min: x_min//input[var1][1][0][0]-0.01
      },
      yAxis: [
        {
          type: 'value',
          position: 'left',
          name: yAxisNameMapFun(input[var1][0]),
          nameTextStyle: {
            align: "left",
            verticalAlign: 'center',
            padding: [0, 0, 0, -50],
            fontWeight: 'bold'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: RGECOLORS.red
            }
          },
        },
        {
          type: 'value',
          position: 'right',
          name: yAxisNameMapFun(input[var2][0]),
          nameTextStyle: {
            align: "right",
            verticalAlign: 'center',
            padding: [0, -50 ,0, 0],
            fontWeight: 'bold'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: RGECOLORS.purple
            }
          },
        }
      ],
      series: [
        {
          data: input[var1][1],
          type: 'line',
          yAxisIndex: 0,
          // showSymbol: false,
          symbolSize: 0.1, 
          color: RGECOLORS.red,
          triggerLineEvent: true,
        },
        {
          data: input[var2][1],
          type: 'line',
          yAxisIndex: 1,
          // showSymbol: false,
          symbolSize: 0.1, 
          color: RGECOLORS.purple,
          triggerLineEvent: true
        }
      ]
    }
  }

  return (
    <>
      <div
        className="patient-view-box">
        <Space direction="vertical" style={{width:'100%'}}>
        {patDemo && <Descriptions title="Demographic" bordered size="small">
          <Descriptions.Item label="MRN">
            {patId}
          </Descriptions.Item>
          <Descriptions.Item
            label="Gender"
            contentStyle={{ backgroundColor: patDemo.genderColor }}
          >
            {patDemo.gender}
          </Descriptions.Item>
          <Descriptions.Item label="Race" contentStyle={{ backgroundColor: patDemo.raceColor }}>
              {patDemo.race}
          </Descriptions.Item>
          <Descriptions.Item label="DoB">{dateOfBirth}</Descriptions.Item>
          <Descriptions.Item label="Last Visit Date">{lastVisit}</Descriptions.Item>
        </Descriptions> }
        {patRisk && <Descriptions bordered size="small" title="Kidney Failure Risk">
          <Descriptions.Item label="Age">
              {patRisk.age}
          </Descriptions.Item>
          <Descriptions.Item label="GFR">
              {patRisk.egfr}
            </Descriptions.Item>
            <Descriptions.Item label="ACR(mg/g Cr)">
              {patRisk.ACR}
            </Descriptions.Item>
            <Descriptions.Item label="2 Year Risk">
              {patRisk.twoyear}%
            </Descriptions.Item>
            <Descriptions.Item label="5 Year Risk">
              {patRisk.fiveyear}%
            </Descriptions.Item>
        </Descriptions> }

      {patConcept && 
          <Space direction="horizontal">
            Clinical Feature: 
            <Select 
              style={{width: '130px'}}
              showSearch
              placeholder="Select a Clinical Feature"
              optionFilterProp="children"
              onChange={onLeftConceptChange}
              // onMouseEnter={onSelectColorChange}
              defaultValue={leftConcept}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              value={leftConcept}
              options={conceptOption}
              />
            <br/> <br/> <br/> <br/> <br/> <br/> 
              <Select 
                style={{width:'150px', float:'right'}}
                showSearch
                placeholder="Select a concept"
                optionFilterProp="children"
                onChange={onRightConceptChange}
                defaultValue={rightConcept}
                value={rightConcept}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={conceptOption}
                />
            </Space>
            }          
            {chartOption && 
              <ReactECharts
                notMerge={true}
                option={chartOption}
                lazyUpdate={false}
              /> }
        </Space>
      </div>
    </>
  );
};

export default PatientView;