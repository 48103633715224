import React, { Component } from "react";
import { Space, Select, Spin } from "antd";
import ReactECharts from 'echarts-for-react';
import { getUmapEmbed, getPatUmapProj } from '../../services/api'
import { RGECOLORS, FROMBACKENDTRAJ, DEFINETRAJCOLORS } from '../../utils/const'
import "./TrajectoryView.less";
import { umapOption, selectOption } from './const'

class TrajectoryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      embed: null,
      chartOption: null,
      patUmapProj: null
    };
  }

  componentDidMount(){
    getUmapEmbed().then(umapRes => {
      this.setState({
        embed: umapRes.data,
      })
      getPatUmapProj(this.props.patId).then(patRes=>{
        this.setState({
          patUmapProj: patRes.data.embed,
          chartOption: this.getOptions(umapRes.data, patRes.data)
        })
     })
    })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.patId !== this.props.patId) {
      getPatUmapProj(this.props.patId).then(patRes=>{
        this.setState({
          patUmapProj: patRes.data.embed,
          chartOption: this.getOptions(this.state.embed, patRes.data)
        })
     })
    }
  }  

  onSelectChange(val){
    var visualMapSet
    if(val==2) { 
      visualMapSet = {
        min: 0,
        max: 100,
        dimension: val,
        orient: 'vertical',
        right: 10,
        top: 'center',
        text:['year', ''],
        calculable: true,
        inRange: {
          color: ['#E5E451', '#3E0751']
        } } 
    }
    if(val==3) 
    {
      visualMapSet = {
        min: 0,
        max: 160,
        dimension: val,
        orient: 'vertical',
        right: 10,
        top: 'center',
        text: ['mL/min/1.73m^2', ''],
        calculable: true,
        inRange: {
          color: ['#24b7f2', '#f2c31a']
        } 
    } 
  }
    this.setState({
      chartOption: {
        ...this.state.chartOption,
        visualMap: visualMapSet,
      }
    })
  }


  getOptions(res, patPoint){
    var option = {
      ...umapOption,
      series: [
        {
          name: 'umap',
          type: 'scatter',
          symbolSize: 1,
          data: res.embed
        },
        {
          name: "Patient Visit Projection",
          type: 'scatter',
          symbolSize: 5,
          itemStyle: {
            borderColor: 'rgba(213, 14, 14, 1)',
            borderWidth: 1.5
          },
          data: patPoint.embed
        }
      ]
    }
    res.traj.map(ele => {
      option.series.push({
        name: ele[0],
        type: 'line',
        symbolSize: 5,
        lineStyle: {
          normal: {
            width: 12,
            opacity: 0.5
          }
        },
        showSymbol: false,
        smooth: 1,
        data: ele[1],
        color: RGECOLORS[DEFINETRAJCOLORS[FROMBACKENDTRAJ[ele[0]]]]
      })
    })
    return option
  }
 

  render() {
    const chartOption = this.state.chartOption
    return (
      <Space key={this.props.patId} direction="vertical" style={{width:'100%'}}>
        Color by:
          <Select 
              style={{width:'50%'}}
              showSearch
              placeholder="Select a concept"
              optionFilterProp="children"
              onChange={this.onSelectChange.bind(this)}
              defaultValue={selectOption[0].label}
              // onSearch={onSelectSearch}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={selectOption}
           />
        {!chartOption && 
          <div className="spin-div"> 
            <Spin size="large" style={{color: '#E58C8C'}}/> 
            <p> UMAP Loading... </p>
          </div>}
        {chartOption && 
            <ReactECharts
              className="echart-style"
              notMerge={true}
              option={chartOption}
              lazyUpdate={false}
        /> }
      </Space>
    );
  }
}

export default TrajectoryView;
