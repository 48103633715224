import axios from "axios";
const currentURL = "https://trajvis.sulab.io/api"; // Local backend
// const currentURL = "http://localhost:5002/api"; // Local backend

axios.defaults.baseURL = currentURL;
export { currentURL };

const session = String(new Date().getTime());
export { session };

export const getAllPatId = () =>
  axios.get("/patients", { session_id: session });

export const getPatRecords = (patId) =>
  axios.get("/patient/"+patId, { params: { session_id: session } });

export const getUmapEmbed = () =>
axios.get("/umap", { session_id: session });

export const getLabTestData = (patId) => 
  axios.get("/labtest/"+patId, { params: { session_id: session } });

export const getPatUmapProj = (patId) => 
axios.get("/umap/"+patId, { params: { session_id: session } });

export const getAnalysisTraj = (patId) => 
axios.get("/analysis/"+patId, { params: { session_id: session } });

export const getAllIndicator = () => 
axios.get("/indicators", { params: { session_id: session } });

export const getAnalysisDist = (concept) => 
axios.get("/analysis/dist/"+concept, { params: { session_id: session } });

