export const RGECOLORS = {
    'green':'#2CA02C',
    'orange':'#FF7F0E',
    'blue': '#1F77B4',
    'red': '#d62728',
    'purple': '#9467bd',
    'pink': '#e377c2',
    'grey':'#7f7f7f',
    'brown':'#6b4841',
    'cyan': '#17becf'
}

export const MEDCOLOR = {
    'green':'#67BF5C',
    'orange':'#FF9E4A',
    'blue':'#729ECE',
    'pink': '#ed97ca',
    'all': '#a8786e',
    'cyan': '#089793',
    'brown':'#8c564b',
}

export const LIGHTCOLOR = {
    'green':'#98DF8A',
    'orange':'#FFBB78',
    'blue':'#AEC7E8',
    'purple': "#C5B0D5",
    'pink': "#F7B6D2",
    'cyan':'#9DDBE5',
    'grey': '#C7C7C7',
    'brown': '#a47369'
}

export const COLORTRAJ_MAP = {
    'orange': 'Fast Progression CKD',
    'blue': 'CKD',
    'green': 'Healthy'
}

export const FROMBACKENDTRAJ = {
    'orange': 'traj3',
    'blue': 'traj2',
    'green': 'traj1'
}

export const DEFINETRAJCOLORS = {
    'traj1': 'green',
    'traj2': 'blue',
    'traj3': 'orange',
    'traj4': 'pink'
}