import { concept_name_dict  } from "../../utils/conceptNameMap"
export const conceptChartOption = {
  title: {
    text:'Patient Visit Record',
    left:'center'
  },
  grid: {
    top:'10%',
    bottom: '15%'
  },
  tooltip: {
    trigger: 'item',
    formatter: function (params) {
      return (
        'age: ' + parseFloat(params.value[0]).toFixed(2)  + ', '+
        'value: '+ parseFloat(params.value[1]).toFixed(2)
      )
    }
  },
  series: [
    {
      data: [
      ],
      type: 'line'
    }
  ]
}

export const genderNameMap = {
  'F':'Female',
  'M':'Male',
  'U':'Unknow'
}


export const raceNameMap = {
  'B':'Black',
  'W':'White',
  'S':'Unknow',
  'O':'Unknow',
  'U':'Unknow',
  'X':'Unknow',
  'I':'Unknow',
  'R':'Unknow',
  'P':'Unknow'
}

export const yAxisNameMapFun = (concept) => {
  return concept_name_dict[concept][0]+concept_name_dict[concept][1]
}