import { concept_name_dict }  from './conceptNameMap'

export const generateOption = (patList) => {
    var optionList = patList.map(element => {
        return (
            {
                value: element,
                label: element,
            }
        )
    })
    return optionList
}

export const genConceptOption = (concept) => {
    var optionList = concept.map((element, index) => {
        return (
            {
                value: index,
                label: element,
            }
        )
    })
    return optionList
}


  export const mapConceptName = (names) => {
    var res = []
    names.map(item => {
      res.push(concept_name_dict[item][0])
    })
    return res
  }
  