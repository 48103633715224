
import { LIGHTCOLOR } from "../../utils/const";
const narrowSplit = 50

export const lineChartOption = {
    tooltip: {
      formatter: function() {
        // this = point
        return this.series.tooltipOptions.customTooltipPerSeries.call(this);
      }
    },
    title: {
      text: 'Trajectory Uncertainty Evolution'
    },
    chart: {
      renderTo: 'container',
      height: 300,
  },
    xAxis: {
        type: 'value',
        min: 25,
        max: 80,
        title: {
          enabled: true,
          text: 'Age'
        }
    },
    yAxis: {
      min: 40,
      max: 115,
      type: 'value',
      title: {
        text: 'eGFR(mg/mL/1.73m^2)'
      }
    },
    credits: {
      enabled: false
    },
    series: [
    ],
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemMarginTop: 10,
      itemMarginBottom: 10
    },
}

export const pieChartOption = {
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)'
  },
  grid: {
    left: '3%',
    top: 20,
    right: 30,
    contianLabel: true
  },
  // legend: {
  //   top: 'center',
  //   right: '10%'
  // },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      selectedMode: 'single',
      radius: ['40%', '60%'],
      label: {
        show: false,
        position: 'center'
      },
      data: [
        { value: 1548, name: 'Search Engine' },
        { value: 775, name: 'Direct' },
      ]
    }
  ]
};

export const barChartOption = {
  title: {
    text: 'title',
  },
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: ['Orange', 'Green', 'Blue'],
    bottom:10
  },
  grid: {
    top:'12%'
  },
  calculable: true,
  xAxis: [
    {
      type: 'category',
      // prettier-ignore
      data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
  ],
  yAxis: [
    {
      type: 'value'
    }
  ],
  series: [
  ]
}

export function unEvenXaxis(data){
    return data.map(element => {
        const x = element[0]
        var y = element[1]
        // y = Math.log10(y+1)
        if(x <= narrowSplit) return [x/3, y]
        else return [x-2*narrowSplit/3, y]
    });
}

const agedata = [[0,141],[1,120],[    2,   171], [    3,   109],[    4,   134],[    5,   153],
       [    6,   153],[    7,   178],[    8,   178],[    9,   199],[   10,   254],
       [   11,   292],[   12,   352],[   13,   442],[   14,   415],[   15,   507],
       [   16,   538],[   17,   565],[   18,   565],[   19,   606],[   20,   577],
       [   21,   593],[   22,   686],[   23,   791],[   24,   835],[   25,   943],
       [   26,   986],[   27,  1135],[   28,  1138],[   29,  1431],[   30,  1659],
       [   31,  1875],[   32,  2059],[   33,  2373],[   34,  2359],[   35,  2662],
       [   36,  2889],[   37,  3264],[   38,  3518],[   39,  4029],[   40,  4479],
       [   41,  4697],[   42,  4877],[   43,  5346],[   44,  5522],[   45,  5807],
       [   46,  6345],[   47,  6859],[   48,  6893],[   49,  7398],[   50,  7900],
       [   51,  8022],[   52,  8371],[   53,  8647],[   54,  8824],[   55,  9056],
       [   56,  9252],[   57,  9713],[   58,  9917],[   59,  9714],[   60,  9950],
       [   61,  9832],[   62,  9675],[   63,  9726],[   64,  9856],[   65, 10115],
       [   66,  9843],[   67,  9393],[   68,  9203],[   69,  8903],[   70,  8293],
       [   71,  7877],[   72,  7804],[   73,  7474],[   74,  7275],[   75,  6847],
       [   76,  6623],[   77,  6361],[   78,  5458],[   79,  5222],[   80,  4686],[   81,  4232],
       [   82,  3913],[   83,  3338],[   84,  3018],[   85,  2562],[   86,  2180],[   87,  1911],[   88,  1515],
       [   89,  1200],[   90,   923],[   91,   734],[   92,   526],[   93,   409],
       [   94,   288],[   95,   196],[   96,   118],[   97,    78],[   98,    56],[   99,    39],[  100,    24],[  101,    15],[  102,     9],[  103,     5],[  104,     3],[  105,     1],[  106,     2],[  108,     1]]

var res = []
export var newData = agedata.map(item => {
    var pair = []
    for (let i = 0; i < item[1]/20; i++){
      var x = Math.random().toFixed(3)
      x = parseInt(item[0])+parseFloat(x)
      const y = Math.random().toFixed(3)
      pair.push([x, y])
    }
    res = res.concat(pair);
})
      
var res;

export const densityOption ={
    xAxis: {
        type: 'value',
        boudaryGap: false,
        min:20,
        max: 80,
        show:true
      },
      yAxis: {
        show:false
      },
      grid: {
        left: 40,
        right: 51,
        containLabel: true
      },
      series: [
        {
          symbolSize: 1,
          data: res,
          type: 'scatter',
          itemStyle: {
            opacity:1,
            color: LIGHTCOLOR.purple
          }
        }
      ]
}



const egfrdata = [
    [0, 1],[1, 1],[2, 89],[3, 632],[4, 1465],[5, 2312],[6, 2830],[7, 3089],[8, 2988],[9, 2819],[10, 2601],
    [11, 2431],[12, 2207],[13, 2112],[14, 2087],[15, 1997],[16, 2031],[17, 2012],[18, 2072],[19, 1998],[20, 2036],
    [21, 2103],[22, 2201],[23, 2238],[24, 2409],[25, 2454],[26, 2492],[27, 2601],[28, 2660],[29, 2727],[30, 2830],
    [31, 2940],[32, 3114],[33, 3187],[34, 3315],[35, 3383],[36, 3531],[37, 3572],[38, 3738],[39, 3843],[40, 3870],
    [41, 4137],[42, 3885],[43, 3959],[44, 4298],[45, 4297],[46, 4241],[47, 4296],[48, 4485],[49, 4554],[50, 4546],
    [51, 4604],[52, 4610],[53, 4569],[54, 4764], [55, 4849],[56, 4814],[57, 4843], [58, 4912], [59, 4855],
    [60, 5081],[61, 4895],[62, 5020],[63, 5008],
 [64, 5106],
 [65, 5075],
 [66, 5039],
 [67, 4918],
 [68, 4972],
 [69, 5081],
 [70, 4924],
 [71, 4981],
 [72, 4912],
 [73, 4955],
 [74, 4884],
 [75, 4910],
 [76, 4739],
 [77, 4792],
 [78, 4641],
 [79, 4722],
 [80, 4599],
 [81, 4720],
 [82, 4675],
 [83, 4697],
 [84, 4558],
 [85, 4440],
 [86, 4552],
 [87, 4547],
 [88, 4679],
 [89, 4470],
 [90, 4555],
 [91, 4398],
 [92, 4362],
 [93, 4391],
 [94, 4407],
 [95, 4286],
 [96, 3985],
 [97, 3987],
 [98, 3955],
 [99, 3806],
 [100, 3718],
 [101, 3480],
 [102, 3409],
 [103, 3304],
 [104, 3175],
 [105, 3064],
 [106, 3142],
 [107, 3031],
 [108, 2729],
 [109, 2725],
 [110, 2595],
 [111, 2467],
 [112, 2378],
 [113, 2296],
 [114, 2101],
 [115, 1924],
 [116, 1929],
 [117, 1886],
 [118, 1662],
 [119, 1552],
 [120, 1577],
 [121, 1415],
 [122, 1353],
 [123, 1238],
 [124, 1133],
 [125, 1190],
 [126, 1018],
 [127, 970],
 [128, 891],
 [129, 902],
 [130, 804],
 [131, 730],
 [132, 689],
 [133, 602],
 [134, 574],
 [135, 543],
 [136, 451],
 [137, 456],
 [138, 428],
 [139, 376],
 [140, 340],
 [141, 321],
 [142, 348],
 [143, 291],
 [144, 307],
 [145, 242],
 [146, 240],
 [147, 231],
 [148, 213],
 [149, 186],
 [150, 213],
 [151, 202],
 [152, 186],
 [153, 166],
 [154, 167],
 [155, 130],
 [156, 122],
 [157, 118],
 [158, 115],
 [159, 116],
 [160, 104],
 [161, 94],
 [162, 100],
 [163, 86],
 [164, 96],
 [165, 93],
 [166, 84],
 [167, 69],
 [168, 53],
 [169, 47],
 [170, 62],
 [171, 59],
 [172, 47],
 [173, 61],
 [174, 45],
 [175, 35],
 [176, 43],
 [177, 36],
 [178, 33],
 [179, 35],
 [180, 36],
 [181, 29],
 [182, 33],
 [183, 26],
 [184, 28],
 [185, 37],
 [186, 42],
 [187, 26],
 [188, 25],
 [189, 14],
 [190, 16],
 [191, 12],
 [192, 15],
 [193, 23],
 [194, 25],
 [195, 11],
 [196, 16],
 [197, 11],
 [198, 8],
 [199, 14],
 [200, 13],
 [201, 13],
 [202, 6],
 [203, 7],
 [204, 18],
 [205, 11],
 [206, 19],
 [207, 14],
 [208, 12],
 [209, 14],
 [210, 13],
 [211, 7],
 [212, 12],
 [213, 21],
 [214, 16],
 [215, 9],
 [216, 14],
 [217, 13],
 [218, 5],
 [219, 11],
 [220, 5],
 [221, 8],
 [222, 1],
 [223, 8],
 [224, 5],
 [226, 1],
 [227, 1],
 [228, 5],
 [229, 3],
 [230, 1],
 [231, 1],
 [232, 5],
 [233, 1],
 [234, 3],
 [235, 2],
 [236, 5],
 [237, 1],
 [239, 2],
 [240, 3],
 [242, 1],
 [243, 1],
 [244, 2],
 [245, 1],
 [246, 1],
 [247, 1],
 [248, 1],
 [249, 9],
 [250, 1],
 [251, 4],
 [252, 5],
 [253, 8],
 [254, 6],
 [255, 2],
 [256, 1],
 [257, 11],
 [258, 8],
 [259, 1],
 [260, 3],
 [261, 4],
 [262, 1],
 [263, 1],
 [264, 1],
 [266, 1],
 [267, 2],
 [268, 2],
 [269, 1],
 [272, 1],
 [273, 1],
 [276, 1],
 [277, 2],
 [278, 1],
 [279, 1],
 [280, 1],
 [281, 6],
 [282, 1],
 [283, 1],
 [284, 1],
 [285, 1],
 [286, 1],
 [287, 4],
 [288, 1],
 [289, 3],
 [290, 2],
 [291, 1],
 [292, 2],
 [298, 2],
 [299, 1],
 [301, 1],
 [309, 1],
 [312, 1],
 [323, 1],
 [325, 1],
 [326, 1],
 [336, 2],
 [338, 1],
 [340, 2],
 [341, 1],
 [342, 1],
 [343, 1],
 [344, 1],
 [346, 2],
 [347, 2],
 [348, 2],
 [377, 1],
 [397, 1]]

export const egfrDensityOption = {
  title: {
    text:'Population eGFR Density',
    textStyle: {
      fontSize: 16
    },
    x:'center'
  },
  xAxis: {
    type: 'value',
    max: 160,
    name: 'eGFR(mg/mL/1.73m^2)',
    nameLocation: 'center',
    nameTextStyle: {
      align: "center",
      nameGap: 30,
      padding: [10, 0, 400, 0],
      fontWeight: 'bold'
    },
  },
  yAxis: {
    type: 'value',
    name: 'counts'
  },
  grid: {
    left: '15%',
    top:'20%'
  },
  series: [
    {
      data: egfrdata,
      type: 'bar'
    }
  ]
}

export const ageDensityOption = {
  title: {
    text:'Population Age Density',
    textStyle: {
      fontSize: 16
    },
    x:'center'
  },
  xAxis: {
    type: 'value',
    max:110,
    name: 'age(year)',
    nameLocation: 'center',
    nameTextStyle: {
      align: "center",
      nameGap: 30,
      padding: [10, 0, 400, 0],
      fontWeight: 'bold'
    },
  },
  yAxis: {
    type: 'value',
    name: 'counts'
  },
  grid: {
    left: '15%',
    top:'20%'
  },
  series: [
    {
      data: agedata,
      type: 'bar'
    }
  ]
}
var egfrRes = []
var yData = egfrdata.map(item=> {
    var pair = []
    for (let i = 0; i < item[1]/20; i++){
      var x = Math.random().toFixed(3)
      x = parseInt(item[0])+parseFloat(x)
      const y = Math.random().toFixed(3)
      pair.push([y, x])
    }
    egfrRes = egfrRes.concat(pair);
})

export var yDensityoption ={
    xAxis: {
        type: 'value',
        boudaryGap: false,
        show:false,
        max: 1
      },
      grid: {
        left: 32
      },
      yAxis: {
        show:true,
        min:20,
        max:125
      },
      series: [
        {
          symbolSize: 1,
          data: egfrRes,
          type: 'scatter',
          itemStyle: {
            opacity:0.5
          }
        }
      ]
}

export const stackBarOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    show: false
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    top: '0%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      show: false
      // data: [1, 2, 3, 4, 5, 6, 7]
    }
  ],
  yAxis: [
    {
      show: false,
      type: 'value'
    }
  ],
  series: [
    {
      name: 'Email',
      type: 'bar',
      stack: 'total',
      emphasis: {
        focus: 'series'
      },
      barWidth: '101%',
      data: [[1, 120], [2, 132], [3, 101], [4, 134], [5, 90], [6, 230], [7,210]]
    },

    {
      name: 'Union Ads',
      type: 'bar',
      stack: 'total',
      emphasis: {
        focus: 'series'
      },
      barWidth: '101%',
      data: [[1, 220], [2, 182], [3, 191], [4, 234], [5, 290], [6, 330], [7,310]]
    }
  ]
}

export const opacityValueDef = (green_poss, orange_poss, blue_poss) => {
  const green_last = green_poss.slice(-1)
  const orange_last = orange_poss.slice(-1)
  const blue_last = blue_poss.slice(-1)
  if (green_last==Math.max(green_last, orange_last, blue_last))
    return [0.8, 0.3, 0.3]
  if (orange_last==Math.max(green_last, orange_last, blue_last))
    return [0.3, 0.8, 0.3]
  if (blue_last==Math.max(green_last, orange_last, blue_last))
    return (0.3, 0.3, 0.8)
}