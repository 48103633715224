import React, { useEffect, useState } from "react";
import "./index.less";
import { Typography, Space, Select, Tooltip } from 'antd';
import { UserOutlined, PartitionOutlined, BarChartOutlined, CalculatorOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import PatientView from "../components/PatientView/PatientView";
import TrajectoryView from "../components/TrajectoryView/TrajectoryView";
import LabTestView from "../components/LabTestView/LabTestView";
import AnalysisView from "../components/AnalysisView/AnalysisView";

import { getAllPatId } from "../services/api";
import { generateOption } from "../utils/generateOptions"
import { RGECOLORS, DEFINETRAJCOLORS } from "../utils/const"

const { Title } = Typography;

const IndexPage = () => {
  const [ patOptions, setPatOptions ] = useState()
  const [ patId, setPatId ] = useState(42747)
  useEffect(() => {
    getAllPatId().then(res => {
      setPatOptions(generateOption(res.data.data))
    })
  }, [])

  // useEffect(() => {
  //   const initialValue = document.body.style.zoom;

  //   // Change zoom level on mount
  //   document.body.style.zoom = "90%";

  //   return () => {
  //     // Restore default value
  //     document.body.style.zoom = initialValue;
  //   };
  // }, []);

  const onSelectChange = (value) => {
    setPatId(value)
  };

  return (
    <div className="page-size">
      <div level={2} className="topbar"> 
        <div className="title">Chronic Kidney Disease Patient Trajectory</div>
        <Space className="pat-id-select">
          <span className="circle" style={{backgroundColor: RGECOLORS[DEFINETRAJCOLORS['traj1']]}}></span>
          <span className="text"> Healthy</span>
          <span className="circle" style={{backgroundColor: RGECOLORS[DEFINETRAJCOLORS['traj2']]}}></span>
          <span className="text"> CKD </span>
          <span className="circle" style={{backgroundColor: RGECOLORS[DEFINETRAJCOLORS['traj3']]}}></span>
          <span className="text"> Fast Progression CKD </span>
          <span> 
              <div>Select a Patient</div>
              <div style={{fontSize: '14px'}}>(All data are synthetic)</div>
          </span>
          <Select 
              style={{width: '200px'}}
              showSearch
              placeholder="Select a patient"
              optionFilterProp="children"
              onChange={onSelectChange}
              value={patId}
              // onSearch={onSelectSearch}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={patOptions}
              />
        </Space>

      </div> 

      <div className="system-content">
      <div className="view-box-1">
        <div className="block patient-view">
            <div className="view-title">
              Patient View <UserOutlined />
            </div>
            <div className="cross-line"></div>
            <PatientView 
              patId = {patId}>
            </PatientView>
        </div>
        {/* Trajectory View */}
        <div className="block trajectory-view">
          <div className="view-title">
            Trajectory View <PartitionOutlined />
          </div>
          <div className="cross-line"></div>
            <TrajectoryView 
              patId = {patId}
            />
        </div>

      </div>

        <div className="view-box-2">
            {/* Clinical Indicator View */}
            <div className="block lab-test-view">
              <div className="view-title">
                <Space >
                  Clinical Indicator View <BarChartOutlined /> 
                  <Tooltip title="The background color of each box indicates which clinical feature at that age contributes to which trajectory.">
                    <QuestionCircleOutlined twoToneColor="#eb2f96" />
                  </Tooltip>
                </Space>
              </div>
              <div className="cross-line"></div>
                <LabTestView
                  patId = {patId}
                ></LabTestView>
          </div>

          {/* analysis view */}
          <div className="block analysis-view">
            <div className="view-title">
              Analysis View <CalculatorOutlined />
            </div>
            <div className="cross-line"></div>
            <AnalysisView
              patId = {patId}
            ></AnalysisView>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
