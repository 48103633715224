export const concept_name_dict = {
    'EGFR': ['eGFR', '(mg/mL/1.73m^2)'],
    'TBIL': ['Total Bilirubin', '(mg/dL)'],
    'BP_DIASTOLIC': ['BP Diastolic', '(mmHg)'],
    'BP_SYSTOLIC': ['BP Systolic', '(mmHg)'],
    'WT': ['Weight', '(pounds)'],
    'HT': ['Height', '(in)'],
    'CHOLESTEROL': ['Cholesterol', '(mg/dL)'],
    'CREATINE_KINASE': ['Creatine kinase', '(units/L)'],
    'HEMOGLOBIN': ['Hemoglobin','(g/dL)'],
    'INR': ['INR', ''],
    'ALT_SGPT': ['ALT SGPT', '(U/L)'],
    'AST_SGOT': ['AST SGOT', '(U/L)'],
    'ALK': ['Alkaline Phosphatase', '(U/L)'],
    'HDL': ['High-Density Lipoprotein', '(mg/dL)'],
    'LDL': ['Low-Density Lipoprotein', '(mg/dL)'],
    'TRIGLYCERIDES': ['Triglycerides', '(mg/dL)'],
    'HBA1C': ['Hemoglobin A1C', '(ng/mL)'],
    'TROPONIN': ['Troponin Test', '(ng/mL)']
  }

export const mapConceptName = (names) => {
    var res = []
    names.map(item => {
      res.push(concept_name_dict[item[0]][0])
    })
    return res
  }

export const mapConceptList = (concepts) => {
  var res = []
  concepts.map(item => {
    res.push(concept_name_dict[item][0])
  })
  return res
}