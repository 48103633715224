import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import ReactECharts from 'echarts-for-react'

import HC_more from 'highcharts/highcharts-more';
import "./AnalysisView.less";
import { getAnalysisTraj, getAllIndicator, getAnalysisDist } from '../../services/api'
import { RGECOLORS, LIGHTCOLOR, MEDCOLOR, COLORTRAJ_MAP, FROMBACKENDTRAJ, DEFINETRAJCOLORS } from "../../utils/const";
// import { genConceptOption, mapConceptName } from "../../utils/generateOptions"


import { opacityValueDef, lineChartOption, pieChartOption, densityOption, egfrDensityOption, ageDensityOption, barChartOption, stackBarOption } from './const'
import { Space, Spin, Switch } from "antd";

HC_more(Highcharts)

const AnalysisView = (props) => {
  const { patId } = props;
  const [ chartOption, setChartOption ] = useState()
  const [ sexPieOption, setSexPieOption ] = useState()
  const [ racePieOption, setRacePieOption ] = useState()
  const [ possBarOption, setPossBarOption ] = useState()
  const [ xDensityOption, setXDensityOption ] = useState()
  const [ responseData, setResponseData ] = useState()
  // const [ distBarOption, setDistBarOption ] = useState(barChartOption)
  // const [ allConcepts, setAllConcepts ] = useState()
  // const [ conceptOption, setConceptOption ] = useState()
  // const [ selectedConcept, setSelectConcept ] = useState('EGFR')

  useEffect(() => {
    getAnalysisTraj(patId).then(res => {
      setResponseData(res.data)
      setChartOption(getOption(res.data))
      setSexPieOption(getSexOption(res.data.sex_dist))
      setRacePieOption(getRaceOption(res.data.race_dist))
      setPossBarOption(getPossBarOption(res.data))
      setXDensityOption(getXdensityOption(res.data))
    })

    // getAllIndicator().then(res=> {
    //   setAllConcepts(res.data)
    //   setConceptOption(genConceptOption(mapConceptName(res.data)))
    // })
    // freshAnalysisDist(selectedConcept)
  }, [patId])

  const lineWidth = 4;
  const getXdensityOption = (data) => {
    return {
      ...densityOption,
      xAxis: {
        type: 'value',
        boudaryGap: false,
        min:data.x_range[0],
        max: data.x_range.slice(-1)[0],
        show:true
      }
    }
  }
  // const freshAnalysisDist = (concept) => {
  //   getAnalysisDist(concept).then(res=> {
  //     setDistBarOption(getBarChartOption(res.data))
  //   })
  // }

  const onSwitchChange = (checked) => {
    if(!checked) {
      setSexPieOption(getSexBarOption())
      setRacePieOption(getRaceBarOption())
    } 
    else {    
      setSexPieOption(getSexOption(responseData.sex_dist))
      setRacePieOption(getRaceOption(responseData.race_dist))
    } 
  };
  const getBarChartOption = (data) => {
    var option = {
      ...barChartOption,
      xAxis: [
        {
          type: 'category',
          data: data.x_vals
        }
      ]
    }

    data.y_vals.map(ele => {
      option.series.push({
        color: MEDCOLOR[ele[0]],
        name: ele[0],
        type: 'bar',
        data: ele[1]
      })
    })
    return option
  }
  const getPossBarOption = (data) => {
    return {
      ...stackBarOption,
      xAxis:{
        type: 'category',
        // show: false,
        data: data.x_range
      },
      series: [
        {
          name: 'Healthy',
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series'
          },
          color:LIGHTCOLOR[DEFINETRAJCOLORS['traj1']],
          barWidth: '101%',
          data: data.green_poss
        },
        {
          name: 'CKD',
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series'
          },
          color:LIGHTCOLOR[DEFINETRAJCOLORS['traj2']],
          barWidth: '101%',
          data: data.blue_poss
        },
        {
          name: 'Fast Progression CKD',
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series'
          },
          barWidth: '101%',
          color:LIGHTCOLOR[DEFINETRAJCOLORS['traj3']],
          data: data.orange_poss
        }
      ]
    }
  }

  const getSexBarOption = () => {
    var option = {
      title: {
        text: 'Gender Distribution'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },  
      grid: {
        left: '10%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: ['Fast CKD', 'CKD', 'Health'],
        axisLabel: {
          rotate:'45'
        }
      },
      yAxis: {
        type: 'value',
        // boundaryGap: [0, 0.01]
        name: 'percentage %'
      },
      series: [
        {
          name: 'Female',
          type: 'bar',
          data: [54, 56, 38],
          color: 'pink'
        },
        {
          name: 'Male',
          type: 'bar',
          data: [{
                    value: 46,
                    itemStyle: {color: MEDCOLOR[DEFINETRAJCOLORS['traj3']]},
                },
                {
                    value: 44,
                    itemStyle: {color: MEDCOLOR[DEFINETRAJCOLORS['traj2']]},
                },
                {
                    value: 62,
                    itemStyle: {color: MEDCOLOR[DEFINETRAJCOLORS['traj1']]},
                }]
        },
      ]
    };
  return option
}

const getRaceBarOption = () => {
  var option = {
    title: {
      text: 'Race Distribution'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },  
    grid: {
      // left: '3%',
      // right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      // data: [COLORTRAJ_MAP['orange'], COLORTRAJ_MAP['blue'], COLORTRAJ_MAP['green']]
      data: ['Fast CKD', 'CKD', 'Health'],
      axisLabel: {
        rotate:'45'
      }
    },
    yAxis: {
      type: 'value',
      name: 'percentage %'
    },
    series: [
      {
        name: 'Black',
        type: 'bar',
        data: [32, 18, 13],
        color: 'grey'
      },
      {
        name: 'White',
        type: 'bar',
        data: [{
                  value: 68,
                  itemStyle: {color: MEDCOLOR[DEFINETRAJCOLORS['traj3']]},
              },
              {
                  value: 82,
                  itemStyle: {color: MEDCOLOR[DEFINETRAJCOLORS['traj2']]},
              },
              {
                  value: 87,
                  itemStyle: {color: MEDCOLOR[DEFINETRAJCOLORS['traj1']]},
              }]
      },
    ]
  };
return option
}

  const getSexOption = (data) => {
    var radius = 40
    var option = {
      ...pieChartOption,
      title: {
        text:'Gender Distribution',
        left: 'center',
        textStyle: {
          fontSize:15
        }
      },
      series: []
    }
    data.map(ele => {
      var rad1 = radius+"%"
      radius = radius+15
      var rad2 = radius+"%"
      option.series.push({
          name: COLORTRAJ_MAP[ele[0]]+' trajectory',
          type: 'pie',
          radius: [rad1, rad2],
          color: [MEDCOLOR.pink, MEDCOLOR[DEFINETRAJCOLORS[FROMBACKENDTRAJ[ele[0]]]]],
          label: {
            show: false,
            position: 'center'
          },
          data: [
            { value: ele[1], name: COLORTRAJ_MAP[ele[0]]+ ' Female' },
            { value: ele[2], name: COLORTRAJ_MAP[ele[0]]+' Male' },
          ]
      })
      radius = radius+2.5
    })
    return option
  }

  const getRaceOption = (data) => {
    var radius = 40
    var option = {
      ...pieChartOption,
      title: {
        left: 'center',
        text:'Race Distribution',
        textStyle: {
          fontSize:15
        }
      },
      series: []
    }
    data.map(ele => {
      var rad1 = radius+"%"
      radius = radius+15
      var rad2 = radius+"%"
      option.series.push({
          name: COLORTRAJ_MAP[ele[0]] +' trajectory',
          type: 'pie',
          radius: [rad1, rad2],
          color: ['grey', MEDCOLOR[DEFINETRAJCOLORS[FROMBACKENDTRAJ[ele[0]]]]],
          label: {
            show: false,
            position: 'center'
          },
          data: [
            { value: ele[1], name: COLORTRAJ_MAP[ele[0]]+' Black' },
            { value: ele[2], name: COLORTRAJ_MAP[ele[0]]+' White' },
          ]
      })
      radius = radius+2.5
    })
    return option
  }

  const getOption = (data) => {
    const opacity = opacityValueDef(data.green_poss, data.orange_poss, data.blue_poss)
    var markline = data.age
    return {
      ...lineChartOption,
      xAxis: {
        type: 'value',
        min: data.x_range[0],
        max: data.x_range[data.x_range.length-1],
        title: {
          enabled: true,
          text: 'Age'
        },
        plotLines: [{
          value: markline,
          dashStyle: 'dash',
          width:1,
          color: '#d33'
        }]
      },
      series:[
        {
          name:'Fast Progression CKD',
          type: 'line',
          data: data.traj.orange,
          zIndex: 1,
          color: RGECOLORS[DEFINETRAJCOLORS['traj3']],
          marker: {
            lineWidth: lineWidth,
            lineColor: RGECOLORS[DEFINETRAJCOLORS['traj3']]
          }
        },
        {
          name:'CKD',
          type: 'line',
          color: RGECOLORS[DEFINETRAJCOLORS['traj2']],
          data: data.traj.blue,
          zIndex: 1,
          marker: {
            lineWidth: lineWidth,
            lineColor: RGECOLORS[DEFINETRAJCOLORS['traj2']]
          }
        },
        {
          name:'Healthy',
          type: 'line',
          color: RGECOLORS[DEFINETRAJCOLORS['traj1']],
          data: data.traj.green,
          zIndex: 1,
          marker: {
            lineWidth: lineWidth,
            lineColor: RGECOLORS[DEFINETRAJCOLORS['traj1']],
          }
        },
      {
        name: 'CKD certainty',
        data: data.blue_area,
        type: 'arearange',
        lineWidth: 0,
        color: MEDCOLOR[DEFINETRAJCOLORS['traj2']],
        fillOpacity: opacity[0],
        zIndex: 0,
        marker: {
          enabled: false,
        },
        tooltip: {
          customTooltipPerSeries: function() {
            return 'CKD uncertainty'
          }
        },
        showInLegend: false
      },      
      {
        name: 'Healthy certainty',
        data: data.green_area,
        type: 'arearange',
        lineWidth: 0,
        color: MEDCOLOR[DEFINETRAJCOLORS['traj1']],
        fillOpacity: opacity[2],
        zIndex: 0,
        marker: {
          enabled: false,
        },
        tooltip: {
          customTooltipPerSeries: function() {
            return 'Healthy uncertainty'
          }
        },
        showInLegend: false
      },
      {
        name: 'Fast Progression CKD certainty',
        data: data.orange_area,
        type: 'arearange',
        lineWidth: 0,
        color: MEDCOLOR[DEFINETRAJCOLORS['traj3']],
        fillOpacity: opacity[1],
        zIndex: 0,
        marker: {
          enabled: false,
        },
        tooltip: {
          customTooltipPerSeries: function() {
            return 'Fast Progression CKD uncertainty'
          }
        },
        showInLegend: false
      },
  ]
    }
  }
  // const onConceptChange = (val) => {
  //   freshAnalysisDist(allConcepts[val])
  //   setSelectConcept(allConcepts[val])
  // }

  return (
    <div className="view">
      {!chartOption && <div className="spin-div"> <Spin size="large"/> <p> Data Loading </p></div> }
      <div className="upper-view">
        <Space direction="vertical" size="middle">
        <Space>Bar Chart
            <Switch 
              className="left-switch" defaultChecked onChange={onSwitchChange} 
                style={{height: 25}}
            /> Radial Chart
            </Space>
        <Space direction="horizontal">
            {sexPieOption && <ReactECharts
              className="pie1"
              style={{height: 240}}
              notMerge={true}
              option={sexPieOption}>
            </ReactECharts>}

          {racePieOption && <ReactECharts
              className="pie2"
              style={{height: 240}}
              option={racePieOption}
              notMerge={true}>
            </ReactECharts>}  
          <Space className="density-view"> 
            {egfrDensityOption && <ReactECharts
                    style={{height: 260}}
                    className="bar1"
                    option={egfrDensityOption}>
                  </ReactECharts>} 
            { ageDensityOption && <ReactECharts 
                            style={{height: 260}}
                            className="bar2"
                            option={ageDensityOption}>
                          </ReactECharts>
            }
        </Space>
      </Space>
      </Space>
      </div>  

      {chartOption && <>
      <div className="lower-view">
        <div className="lower-upper">
        { chartOption && <HighchartsReact 
            className="lower-upper"
            // style={{height:'200px', width: '200px'}}
            highcharts={Highcharts} 
            options={chartOption} />}
         </div>
        <div className="lower-lower">
        { possBarOption && <ReactECharts
          className="lower-lower"
          style={{height:'140px', width: '87%', 'float':'left'}}
          option={possBarOption}
        /> }
        </div>
      </div>
     </>
     }
    </div>
  );
};

export default AnalysisView;
